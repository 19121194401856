import React, { useContext, useEffect, useState } from "react";
import useTranslation from "../util/useTranslate";
import { Table, Button } from "react-bootstrap";
import { SocketContext } from "util/SocketProvider";
import { useNavigate } from "react-router-dom";
import { ArrowClockwise, Plus } from "react-bootstrap-icons";
import Navbar from "Components/Navbar";
import ParallaxBackground from "Components/ParallaxBackground";
import CreateGameModal from "Components/CreateGameModal";
import JoinGameModal from "Components/JoinGameModal";

const Lobby = () => {
  const [showCreateGameModal, setShowCreateGameModal] = useState(false);
  const [joinGameModalSessionId, setJoinGameModalSessionId] = useState(null);
  const [sessionsList, setSessionsList] = useState([]);
  const translate = useTranslation();
  const { socket } = useContext(SocketContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (socket) {
      socket.on("updateSessionsList", (sessions) => {
        setSessionsList(sessions);
      });

      socket.on("sessionJoined", (sessionId) => {
        navigate(`/game/${sessionId}`);
      });

      socket.emit("refreshSessions");

      return () => {
        socket.off("sessionJoined");
        socket.off("updateSessionsList");
      };
    }
  }, [socket, navigate]);

  const handleRefreshClick = () => {
    if (socket) {
      socket.emit("refreshSessions");
    }
  };

  return (
    <>
      <Navbar />
      <div className="container mt-4">
        <div className="d-flex justify-content-between">
          <Button
            style={{ width: "auto" }}
            variant="success"
            onClick={() => setShowCreateGameModal(true)}
          >
            {translate("createGame")} <Plus />
          </Button>
          <Button
            style={{ width: "auto" }}
            variant="primary"
            onClick={handleRefreshClick}
          >
            {translate("refresh")} <ArrowClockwise />
          </Button>
        </div>

        <Table striped bordered className="mt-4">
          <thead>
            <tr>
              <th>{translate("name")}</th>
              <th>{translate("playerNumber")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {sessionsList.map(({ gameName, sessionId, playersNum }) => (
              <tr key={sessionId}>
                <td>{gameName}</td>
                <td>{playersNum}/4</td>
                <td width={200}>
                  <Button
                    variant="primary"
                    onClick={() => setJoinGameModalSessionId(sessionId)}
                  >
                    {translate("joinGame")}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <ParallaxBackground
        src={"backgrounds/cardsBottom.png"}
        position={"bottom center"}
        size={"60vw"}
      />
      <CreateGameModal
        show={showCreateGameModal}
        onHide={() => setShowCreateGameModal(false)}
      />
      <JoinGameModal
        sessionId={joinGameModalSessionId}
        onHide={() => setJoinGameModalSessionId(null)}
      />
    </>
  );
};

export default Lobby;
