import React from "react";

const ParallaxBackground = ({ src, position, size }) => {


  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundImage: `url(${src})`,
        backgroundSize: size || "contain",
        backgroundPosition: position || "bottom",
        zIndex: -1,
        backgroundRepeat: "no-repeat"
      }}
    />
  );
};

export default ParallaxBackground;
