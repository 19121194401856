import React, { useContext, useMemo } from "react";
import useTranslation from "../util/useTranslate";
import { Button, Card, Container, Row } from "react-bootstrap";
import { SocketContext } from "util/SocketProvider";
import { useNavigate, useParams } from "react-router-dom";
import PlayerIcon from "./PlayerIcon";
import ParallaxBackground from "./ParallaxBackground";

const Score = ({ gameSettings }) => {
  const translate = useTranslation();
  const { socket } = useContext(SocketContext);
  const { gameId } = useParams();
  const navigate = useNavigate();

  const handlePlayerReadyToggle = () => {
    if (socket) {
      socket.emit("toggleReady", gameId);
    }
  };

  const backToStart = () => {
    navigate("/");
  };

  const getTeamColor = (team) => {
    return team === "1" ? "red" : "blue";
  };

  const getTeamText = (team) => {
    const key =
      team === "1" ? gameSettings.teamNames[0] : gameSettings.teamNames[1];
    return translate(key);
  };

  const isGameEnded = useMemo(
    () =>
      Object.keys(gameSettings.score).some(
        (key) => gameSettings.score[key] >= gameSettings.gameLimit
      ),
    [gameSettings]
  );

  const victoryTeam = useMemo(
    () => (gameSettings.score[1] === gameSettings.gameLimit ? "1" : "2"),
    [gameSettings]
  );

  const currentPlayer = useMemo(
    () => gameSettings.players.find((item) => item.id === socket.id),
    [gameSettings.players, socket.id]
  );

  const playerAnswered = useMemo(
    () =>
      gameSettings.players.find(
        (item) => item.id === gameSettings?.question?.playerAnswering
      ),
    [gameSettings]
  );
  
  return (
    <>
      <Container className="vh-100">
        <Row className="justify-content-center align-items-center pt-4">
          <h1 style={{ width: "auto" }}>
            <span style={{ color: getTeamColor(playerAnswered?.team.toString()) }}>
              {playerAnswered?.name}
            </span>{" "}
            {gameSettings?.isAnswerCorrect ? translate("scoreCorrect") : translate("scoreIncorrect")}
          </h1>
        </Row>
        {gameSettings.score &&
          Object.keys(gameSettings.score).map((item) => (
            <Row key={item} className="justify-content-center align-items-center mt-4">
              <h2 style={{ width: "auto", color: getTeamColor(item) }}>
                {getTeamText(item)} : {gameSettings.score[item]}
              </h2>
            </Row>
          ))}
        <Row className="mt-4 justify-content-center align-items-center">
          <Button
            className="w-50"
            variant={currentPlayer.ready ? "success" : "primary"}
            onClick={isGameEnded ? backToStart : handlePlayerReadyToggle}
          >
            {isGameEnded ? translate("back") : translate("nextGame")}
          </Button>
        </Row>
        <Card className="d-flex gap-4 mt-4 p-3 flex-row align-items-center justify-content-center">
          {isGameEnded ? (
            <h3>
              <span style={{ color: getTeamColor(victoryTeam) }}>
                {getTeamText(victoryTeam)}
              </span>{" "}
              {translate("congratulations")}
            </h3>
          ) : (
            <>
              <h3>{translate("playersReady")}</h3>{" "}
              {gameSettings.players.map((player, index) => (
                <PlayerIcon
                  key={player.id}
                  playerIndex={index}
                  active={player.ready}
                />
              ))}
            </>
          )}
        </Card>
      </Container>
      <ParallaxBackground
        src={"/backgrounds/banana.png"}
        position={"bottom left"}
        size={"40vh"}
      />
      <ParallaxBackground
        src={"/backgrounds/kapula.png"}
        position={"bottom right"}
        size={"35vh"}
      />
    </>
  );
};

export default Score;
