import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { SocketContext } from "util/SocketProvider";
import useTranslation from "util/useTranslate";

const ChangeTeamNameModal = ({ show, sessionId, currentName, onHide }) => {
  const [name, setName] = useState(currentName || "");

  const translate = useTranslation();
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    if(show) {
      setName(currentName);
    }
  }, [show, currentName])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (socket) {
      socket.emit("teamName", { name, sessionId });
    }
    onHide();
  };

  const disabled = !name

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{translate("changeTeamName")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="playerName">
            <Form.Label>{translate("enterName")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={translate("name")}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={disabled} variant="primary" type="submit">
          {translate("changeTeamName")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ChangeTeamNameModal;
