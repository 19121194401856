import { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import { SocketContext } from "util/SocketProvider";
import useTranslation, { LanguageContext, LANGUAGES } from "util/useTranslate";

const NavbarComp = () => {
  const translate = useTranslation();
  const { socket } = useContext(SocketContext);
  const [gameCounter, setGameCounter] = useState();
  const { setLanguage } = useContext(LanguageContext);

  useEffect(() => {
    if (socket) {
      socket.emit("gameCounter");
      socket.on("gameCounter", (counter) => {
        setGameCounter(counter);
      });
    }
    return () => {
      if (socket) {
        socket.off("gameCounter");
      }
    };
  }, [socket]);

  return (
    <Navbar expand="md" className="bg-body-tertiary mb-3">
      <Container fluid>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand`}
          aria-labelledby={`offcanvasNavbarLabel-expand`}
          placement="start"
        >
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-start flex-grow-1 pe-3">
              <Nav.Link as={Link} to="/lobby">
                {translate("newGame")}
              </Nav.Link>
              <Nav.Link as={Link} to="/about">
                {translate("aboutProject")}
              </Nav.Link>
              <Nav.Link as={Link} to="/rules">
                {translate("rules")}
              </Nav.Link>
              <Nav.Link as={Link} to="/impressum">
                {translate("impressum")}
              </Nav.Link>
              <Nav.Link as={Link} to="/diningEtiquette">
                {translate("diningEtiquette")}
              </Nav.Link>
              <Nav.Link href="https://forms.gle/tPQduZKMzUCcbnov5" target="_blank">{translate("evaluation")}</Nav.Link>
              <NavDropdown
                title={translate("language")}
                id={`offcanvasNavbarDropdown-expand`}
                align="start"
              >
                <NavDropdown.Item onClick={() => setLanguage(LANGUAGES.CRO)}>
                  {translate("languageNameCro")}
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setLanguage(LANGUAGES.EN)}>
                  {translate("languageNameEn")}
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>

        <Nav className="justify-content-end">
          <Nav.Item className="nav-link">
            {translate("gamesPlayed")} {gameCounter}
          </Nav.Item>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default NavbarComp;
