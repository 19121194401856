import React, { useCallback, useContext, useMemo, useState } from "react";
import useTranslation from "../util/useTranslate";
import { Button, Container, Row, Col } from "react-bootstrap";
import {
  ArrowRightCircle,
  ArrowLeftCircle,
  CheckCircleFill,
  Pencil,
} from "react-bootstrap-icons";
import { SocketContext } from "util/SocketProvider";
import { useParams } from "react-router-dom";
import PlayerIcon from "./PlayerIcon";
import ChangeTeamNameModal from "./ChangeTeamNameModal";

const TEAMS = [1, null, 2];

const GameSettings = ({ gameSettings }) => {
  const { gameId } = useParams();
  const translate = useTranslation();
  const { socket } = useContext(SocketContext);
  const [showChangeTeamNameModal, setShowChangeTeamNameModal] = useState(false);

  const handleJoinTeam = (direction) => {
    let teamIndex = TEAMS.findIndex((item) => item === currentPlayer.team);
    if (socket) {
      socket.emit("joinTeam", {
        team: TEAMS[teamIndex + direction],
        sessionId: gameId,
      });
    }
  };

  const handlePlayerReadyToggle = () => {
    if (socket) {
      socket.emit("toggleReady", gameId);
    }
  };

  const { redTeam, neutral, blueTeam } = useMemo(
    () => ({
      redTeam: gameSettings.players.filter((item) => item.team === 1),
      neutral: gameSettings.players.filter((item) => item.team === null),
      blueTeam: gameSettings.players.filter((item) => item.team === 2),
    }),
    [gameSettings.players]
  );

  const currentPlayer = useMemo(
    () => gameSettings.players.find((item) => item.id === socket.id),
    [gameSettings.players, socket.id]
  );

  const getPlayerIndex = useCallback(
    (id) => gameSettings.players.findIndex((item) => item.id === id),
    [gameSettings.players]
  );

  return (
    <>
      <Container className="mt-4">
        <Row className="gap-3">
          <Col
            className="d-flex justify-content-center align-items-center"
            style={{ border: "1px solid red", height: 30, borderRadius: 10 }}
          >
            <h4>{gameSettings.teamNames[0]}</h4>
          </Col>
          <Col className="d-flex justify-content-center align-items-center">
            <h4>{translate("team")}</h4>
          </Col>
          <Col
            className="d-flex justify-content-center align-items-center"
            style={{ border: "1px solid blue", height: 30, borderRadius: 10 }}
          >
            <h4>{gameSettings.teamNames[1]}</h4>
          </Col>
        </Row>
        <Row className="gap-3">
          <Col
            style={{
              width: "30vw",
              minHeight: 280,
              height: "40vh",
              borderRadius: "15px",
              background: "url(/backgrounds/redCard.png)",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top center",
            }}
          >
            {redTeam.map((item) => (
              <Row className="mt-4" key={item.id}>
                <Col
                  className="d-flex justify-content-between align-items-center"
                  style={{
                    maxWidth: "27vw",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    background: "white",
                    border: "1px solid black",
                    height: 30,
                    borderRadius: 10,
                    margin: "0 10px",
                  }}
                >
                  <h4>{item.name}</h4>{" "}
                  <PlayerIcon
                    playerIndex={getPlayerIndex(item.id)}
                    active={item.ready}
                  />
                </Col>
              </Row>
            ))}
          </Col>
          <Col style={{ minHeight: 280,  width: "30vw" }}>
            {neutral.map((item) => (
              <Row className="mt-4" key={item.id}>
                <Col
                  className="d-flex justify-content-between align-items-center"
                  style={{
                    maxWidth: "27vw",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    background: "white",
                    border: "1px solid black",
                    height: 30,
                    borderRadius: 10,
                    margin: "0 10px",
                  }}
                >
                  <h4>{item.name}</h4>{" "}
                  <PlayerIcon
                    playerIndex={getPlayerIndex(item.id)}
                    active={item.ready}
                  />
                </Col>
              </Row>
            ))}
          </Col>
          <Col
            style={{
              width: "30vw",
              minHeight: 280,
              height: "40vh",
              borderRadius: "15px",
              background: "url(/backgrounds/blueCard.png)",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top center",
            }}
          >
            {blueTeam.map((item) => (
              <Row className="mt-4" key={item.id}>
                <Col
                  className="d-flex justify-content-between align-items-center"
                  style={{
                    maxWidth: "27vw",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    background: "white",
                    border: "1px solid black",
                    height: 30,
                    borderRadius: 10,
                    margin: "0 10px",
                  }}
                >
                  <h4>{item.name}</h4>{" "}
                  <PlayerIcon
                    playerIndex={getPlayerIndex(item.id)}
                    active={item.ready}
                  />
                </Col>
              </Row>
            ))}
          </Col>
        </Row>

        <Container className="p-3 mt-2">
          <Row className="gap-4">
          <Col className="d-flex gap-4 flex-row align-items-center justify-content-center">
            <Button
              size="lg"
              variant="primary"
              disabled={
                currentPlayer.team === 1 ||
                (redTeam.length === 2 && currentPlayer.team === null) ||
                currentPlayer.ready
              }
              onClick={() => handleJoinTeam(-1)}
            >
              <ArrowLeftCircle />
            </Button>
            <Button
              size="lg"
              disabled={currentPlayer.team === null}
              variant={currentPlayer.ready ? "success" : "primary"}
              onClick={handlePlayerReadyToggle}
            >
              {translate("ready")} <CheckCircleFill />
            </Button>
            <Button
              size="lg"
              disabled={
                currentPlayer.team === 2 ||
                (blueTeam.length === 2 && currentPlayer.team === null) ||
                currentPlayer.ready
              }
              variant="primary"
              onClick={() => handleJoinTeam(1)}
            >
              <ArrowRightCircle />
            </Button>
          </Col>
          </Row>
          <Row className="mt-3">
          <Col className="d-flex justify-content-center">
            <Button
              size="lg"
              disabled={
                !currentPlayer.team||
                ![blueTeam[0]?.id, redTeam[0]?.id].includes(currentPlayer.id) ||
                currentPlayer.ready
              }
              variant="primary"
              onClick={() => setShowChangeTeamNameModal(true)}
            >
              {translate("changeTeamName")} <Pencil />
            </Button>
          </Col>
          </Row>
        </Container>
      </Container>
      <ChangeTeamNameModal
        show={showChangeTeamNameModal}
        sessionId={gameId}
        currentName={gameSettings.teamNames[currentPlayer.team - 1]}
        onHide={() => setShowChangeTeamNameModal(false)}
      />
    </>
  );
};

export default GameSettings;
