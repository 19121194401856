import React from "react";
import { useNavigate } from "react-router-dom";
import ParallaxBackground from "Components/ParallaxBackground";

const Home = () => {
  const navigate = useNavigate();

  const startGame = () => {
    navigate("/lobby");
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Adjust as needed
        }}
      >
        <button
        onClick={startGame}
          style={{
            backgroundImage: `url(play.png)`,
            height: "45vh",
            width: "45vh",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            borderRadius: "50%",
            backgroundColor: "white"
          }}
        ></button>
      </div>
      <ParallaxBackground src={"backgrounds/frontPage.jpg"} />
    </>
  );
};

export default Home;
