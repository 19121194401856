import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import useTranslation from "../util/useTranslate";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Row,
} from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { SocketContext } from "util/SocketProvider";
import { useParams } from "react-router-dom";
import { CheckCircleFill, Trophy, X as XIcon } from "react-bootstrap-icons";
import ParallaxBackground from "./ParallaxBackground";
import PlayerIcon from "./PlayerIcon";

const CardTable = ({ gameSettings }) => {
  const translate = useTranslation();
  const { socket } = useContext(SocketContext);
  const [chatMessages, setChatMessages] = useState([]);
  const [currentChatMessage, setCurrentChatMessage] = useState("");
  const [currentPlayerHand, setCurrentPlayerHand] = useState([]);
  const [cardAnimations, setCardAnimations] = useState([]);
  const [currentTableSelection, setCurrentTableSelection] = useState(null);
  const [currentHandSelection, setCurrentHandSelection] = useState(null);
  const { gameId } = useParams();

  useEffect(() => {
    if (socket) {
      socket.emit("getPlayerHand", gameId);
    }
  }, [gameId, socket]);

  useEffect(() => {
    if (socket) {
      socket.on("playerHand", (newHand) => {
        setCurrentPlayerHand(newHand);
      });
      socket.on("chatMessage", (newMessage) => {
        setChatMessages((prev) => [newMessage, ...prev]);
      });
      socket.on("playerCardTaken", (newAnimation) => {
        if (newAnimation.playerId !== socket.id) {
          setCardAnimations((prev) => [
            ...prev,
            { id: uuidv4(), started: false, ...newAnimation },
          ]);
        }
      });
    }
    return () => {
      if (socket) {
        socket.off("playerHand");
        socket.off("chatMessage");
        socket.off("playerCardTaken");
      }
    };
  }, [socket]);

  useEffect(() => {
    if (currentTableSelection !== null && currentHandSelection !== null) {
      socket.emit("swapCards", {
        sessionId: gameId,
        hand: currentHandSelection,
        table: currentTableSelection,
      });
      setCurrentTableSelection(null);
      setCurrentHandSelection(null);
    }
  }, [currentHandSelection, currentTableSelection, socket, gameId]);

  useEffect(() => {
    if (
      currentTableSelection !== null &&
      !gameSettings.tableCards.includes(currentTableSelection)
    ) {
      setCurrentTableSelection(null);
    }
  }, [gameSettings.tableCards, currentTableSelection]);

  const handlePlayerReadyToggle = (e) => {
    if (socket) {
      socket.emit("toggleReady", gameId);
    }
  };

  const currentPlayer = useMemo(
    () => gameSettings.players.find((item) => item.id === socket.id),
    [gameSettings.players, socket.id]
  );

  useEffect(() => {
    const notStartedAnimationIds = cardAnimations
      .filter((item) => !item.started)
      .map((item) => item.id);
    if (notStartedAnimationIds.length > 0) {
      setCardAnimations((prev) =>
        prev.map((item) =>
          notStartedAnimationIds.includes(item.id)
            ? { ...item, started: true }
            : item
        )
      );
      notStartedAnimationIds.forEach((item) => {
        setTimeout(() => {
          setCardAnimations((prev) =>
            prev.filter((animation) => animation.id !== item)
          );
        }, 1100);
      });
    }
  }, [cardAnimations]);

  const sendChatMessage = (e) => {
    e.preventDefault();
    if (socket) {
      socket.emit("sendChatMessage", {
        sessionId: gameId,
        message: currentChatMessage,
      });
      setCurrentChatMessage("");
    }
  };

  const handleEndGameGuess = (guess, doubleScore = false) => {
    if (socket) {
      socket.emit("endGame", {
        sessionId: gameId,
        guess,
        doubleScore
      });
    }
  };

  const playersReadyCount = useMemo(
    () => gameSettings.players.filter((item) => item.ready).length,
    [gameSettings]
  );

  const cardAnimationsTablePositions = useMemo(() => {
    let animations = cardAnimations.filter((item) => item.started);
    let tablePositions = Array(4).fill([]);
    animations.forEach((item) => {
      tablePositions[item.index] = [...tablePositions[item.index], item];
    });
    return tablePositions;
  }, [cardAnimations]);

  const getTeamColor = (team) => {
    return team === 1 ? "red" : "blue";
  };

  const getPlayerIndex = useCallback(
    (id) => gameSettings.players.findIndex((item) => item.id === id),
    [gameSettings.players]
  );

  return (
    <>
      <style type="text/css">
        {`
          @keyframes nameMove {
            from {top: 20vh;}
            to {top: 0;}
          }

          .img-fluid {
            transition: transform .3s;
            cursor: pointer;
          }
          .img-fluid.selected {
            transform: scale(1.1);
          }

          .animatedName {
            top: 0;
            animation-name: nameMove;
            animation-duration: 1s;
            position: absolute;
            font-weight: bold;
            z-index: 2;
          }
      `}
      </style>

      <Container>
        <Row className="justify-content-center d-flex py-4">
          <h4 style={{ width: "auto" }}>{translate("table")}</h4>
        </Row>
        <Row>
          {" "}
          {cardAnimationsTablePositions.map((item, index) => (
            <Col
              key={index}
              style={{ position: "relative" }}
              xs={3}
              className="h-0 p-0"
            >
              {item.map((animation) => (
                <div
                  key={animation.id}
                  style={{ color: getTeamColor(animation.team) }}
                  className="animatedName w-100 text-center"
                >
                  {animation.playerName}
                </div>
              ))}
            </Col>
          ))}
        </Row>

        <Row style={{ height: "30vh" }}>
          {gameSettings.tableCards.map((item, index) => (
            <Col
              style={{ position: "relative" }}
              key={item}
              xs={3}
              className="h-100 p-0 justify-content-center d-flex"
            >
              <Image
                onClick={() => setCurrentTableSelection(item)}
                className={`h-100 ${
                  item === currentTableSelection ? "selected" : undefined
                }`}
                fluid
                style={{ objectFit: "contain" }}
                src={`/cards/${item}.jpg`}
              />
            </Col>
          ))}
        </Row>
        <Row style={{ height: "30vh" }}>
          {currentPlayerHand.map((item) => (
            <Col
              key={item}
              xs={3}
              className="h-100 p-0 justify-content-center d-flex"
            >
              <Image
                onClick={() => setCurrentHandSelection(item)}
                className={`h-100 ${
                  item === currentHandSelection ? "selected" : undefined
                }`}
                fluid
                style={{ objectFit: "contain" }}
                src={`/cards/${item}.jpg`}
              />
            </Col>
          ))}
        </Row>
        <Row className="d-flex justify-content-center my-4">
          <h4 style={{ width: "auto" }}>{translate("myHand")}</h4>
        </Row>
        <div style={{ minHeight: 190 }}>
          <Row className="h-100 d-flex justify-content-center">
            <Row className="h-100 d-flex justify-content-center">
              <Card
                className="mb-2"
                style={{ overflowY: "auto", height: "120px" }}
              >
                <Card.Body>
                  {chatMessages.map(({ id, name, message, team }, index) => (
                    <div key={index}>
                      <PlayerIcon
                        inline
                        playerIndex={getPlayerIndex(id)}
                        active
                      />
                      <span style={{ color: getTeamColor(team) }}>
                        <b>{name}</b>:
                      </span>{" "}
                      {message}
                    </div>
                  ))}
                </Card.Body>
              </Card>
              <form style={{ padding: 0 }} onSubmit={sendChatMessage}>
                <Row>
                  <Col xs={9}>
                    {" "}
                    <Form.Control
                      type="text"
                      value={currentChatMessage}
                      onChange={(e) => setCurrentChatMessage(e.target.value)}
                      placeholder={translate("writeMessage")}
                    />
                  </Col>
                  <Col xs={3}>
                    <Button type="submit" className="w-100" variant="primary">
                      {translate("send")}
                    </Button>
                  </Col>
                </Row>
              </form>
            </Row>
            <Row className="mt-2 mb-2" style={{ paddingRight: 0}}>
              <Row className="gap-2" style={{ paddingRight: 0}}>
                <Col style={{ padding: 0 }} sm={4}>
                  <Button
                    className="w-100 mb-2"
                    variant={currentPlayer.ready ? "success" : "primary"}
                    onClick={handlePlayerReadyToggle}
                  >
                    {translate("ready")} {playersReadyCount}/4{" "}
                    <CheckCircleFill />
                  </Button>
                </Col>
                <Col>
                  <Row className="gap-2">
                    <Col style={{ padding: 0 }}>
                      <Button
                        className="w-100"
                        variant="success"
                        disabled={gameSettings.isFirstRound}
                        onClick={() => handleEndGameGuess(true, true)}
                      >
                        {translate("winCallDouble")} <Trophy /><Trophy />
                      </Button>
                    </Col>
                    <Col style={{ padding: 0 }}>
                      <Button
                        className="w-100"
                        variant="success"
                        disabled={gameSettings.isFirstRound}
                        onClick={() => handleEndGameGuess(true)}
                      >
                        {translate("winCall")} <Trophy />
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Button
                      className="w-100 mt-1 mb-2"
                      variant="danger"
                      disabled={gameSettings.isFirstRound}
                      onClick={() => handleEndGameGuess(false)}
                    >
                      {translate("loseCall")} <XIcon />
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Row>
          </Row>
        </div>
      </Container>
      <ParallaxBackground
        src={"/backgrounds/table.png"}
        position={"bottom left"}
        size={"100vw"}
      />
    </>
  );
};

export default CardTable;
