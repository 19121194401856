import React, { useContext, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { SocketContext } from "util/SocketProvider";
import useTranslation from "util/useTranslate";

const CreateGameModal = ({ show, onHide }) => {
  const [name, setName] = useState("");
  const [gameLimit, setGameLimit] = useState("");
  const [gameName, setGameName] = useState("");

  const translate = useTranslation();
  const { socket } = useContext(SocketContext);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (socket) {
      socket.emit("createSession", { name, gameName, gameLimit });
    }
    onHide();
  };

  const disabled = !name || !gameName || !gameLimit

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{translate("createGame")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="playerName">
            <Form.Label>{translate("enterName")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={translate("name")}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="gameName" className="mt-3">
            <Form.Label>{translate("enterGameName")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={translate("gameName")}
              value={gameName}
              onChange={(e) => setGameName(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="bestInGames" className="mt-3">
            <Form.Label>{translate("gameLimit")}</Form.Label>
            <Form.Control 
              as="select"
              value={gameLimit}
              onChange={(e) => setGameLimit(e.target.value)}
            >
              <option value="">{translate("select")}</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={disabled} variant="primary" type="submit">
          {translate("createGame")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CreateGameModal;
