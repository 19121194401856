const PlayerIcon = ({ playerIndex, active, inline }) => {
  if (playerIndex === undefined || playerIndex > 3 || playerIndex < 0) {
    return null;
  }

  return (
    <div
      style={{
        display: inline ? 'inline-block' : undefined,
        backgroundImage: `url(/icons/icon-${playerIndex+1}.png)`,
        height: "20px",
        width: "20px",
        opacity: active ? undefined : "0.6",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
      }}
    ></div>
  );
};

export default PlayerIcon;
