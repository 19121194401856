import React, { useContext, useEffect, useState } from "react";
import { SocketContext } from "util/SocketProvider";
import { useNavigate, useParams } from "react-router-dom";
import GameSettings from "Components/GameSettings";
import CardTable from "Components/CardTable";
import Discussion from "Components/Discussion";
import Question from "Components/Question";
import Score from "Components/Score";

const Game = () => {
  const { gameId } = useParams();
  const { socket } = useContext(SocketContext);
  const [gameSettings, setGameSettings] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (socket) {
      socket.on("updateGameSettings", (gameSettings) => {
        if (gameSettings) {
          setGameSettings(gameSettings);
        } else {
          navigate(`/lobby`);
        }
      });

      return () => {
        socket.off("updateGameSettings");
      };
    }
  }, [socket, navigate]);

  useEffect(() => {
    if (socket && gameId) {
      socket.emit("getGameSettings", gameId);
    } else {
      navigate(`/lobby`);
    }
  }, [socket, navigate, gameId]);

  switch (gameSettings?.stage) {
    case "GAME_SETTINGS":
      return <GameSettings gameSettings={gameSettings} />;
    case "DISCUSSION":
      return <Discussion gameSettings={gameSettings} />;
    case "GAME":
      return <CardTable gameSettings={gameSettings} />;
    case "END_QUESTION":
      return <Question gameSettings={gameSettings} />;
    case "SCORE":
      return <Score gameSettings={gameSettings} />;
    default:
      return null;
  }
};

export default Game;
