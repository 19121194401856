import React, { useContext, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { SocketContext } from "util/SocketProvider";
import useTranslation from "util/useTranslate";

const JoinGameModal = ({ sessionId, onHide }) => {
  const [name, setName] = useState("");

  const translate = useTranslation();
  const { socket } = useContext(SocketContext);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (socket) {
      socket.emit("joinSession", { name, sessionId });
    }
    onHide();
  };

  const disabled = !name

  return (
    <Modal show={!!sessionId} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{translate("joinGame")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="playerName">
            <Form.Label>{translate("enterName")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={translate("name")}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={disabled} variant="primary" type="submit">
          {translate("joinGame")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default JoinGameModal;
