import PdfViewer from "Components/PdfViewer";
import croPdf from "../pdfs/cro/impresum.pdf";
import enPdf from "../pdfs/en/impresum.pdf";
import Navbar from "Components/Navbar";
import { LANGUAGES } from "util/useTranslate";
import ParallaxBackground from "Components/ParallaxBackground";

const Impressum = () => {
  return (
    <>
      <Navbar />
      <PdfViewer
        files={{
          [LANGUAGES.CRO]: croPdf,
          [LANGUAGES.EN]: enPdf,
        }}
      />
      <ParallaxBackground src={"/backgrounds/dvopek.png"} opacity={0.55} position={"bottom left"} size={"45vh"} />
    </>
  );
};

export default Impressum;
