import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Container, Pagination } from "react-bootstrap";
import { Document, Page } from "react-pdf";
import { LanguageContext } from "util/useTranslate";

const PdfViewer = ({ files }) => {
  const { language } = useContext(LanguageContext);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const containerRef = useRef(null);
  const [size, setSize] = useState({ height: 0, width: 0});

  const { pages, hasEllipsisAfter, hasEllipsisBefore } = useMemo(() => {
    const pages = Array.from(
      { length: 5 },
      (_, index) => pageNumber - 2 + index
    ).filter((item) => item > 0 && item <= numPages);
    const hasEllipsisBefore = pages[0] !== 1;
    const hasEllipsisAfter = pages[pages.length - 1] !== numPages;

    return {
      hasEllipsisAfter,
      hasEllipsisBefore,
      pages,
    };
  }, [numPages, pageNumber]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function setPage(number) {
    setPageNumber(number);
  }

  useEffect(() => {
    const element = containerRef.current;
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { height, width} = entry.contentRect;
        setSize({ width, height});
      }
    });

    if (element) {
      resizeObserver.observe(element);
    }

    return () => {
      if (element) {
        resizeObserver.unobserve(element);
      }
    };
  }, []);

  const heightLimitReached = size.height < 100

  return (
    <Container ref={containerRef} className="mt-2 mb-2" style={{ display:"flex", flexDirection: "column", alignItems:"center", height: "calc(100vh - 150px)"}}>
      <Document file={files[language]} onLoadSuccess={onDocumentLoadSuccess}>
        <Page
          height={heightLimitReached ? undefined : Math.max(size.height, 500)}
          width={heightLimitReached ? size.width : undefined}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          pageNumber={pageNumber}
        />
      </Document>
      {numPages && numPages > 1 && (
        <Pagination size="sm" className="mt-2">
          <Pagination.Prev />
          {hasEllipsisBefore && (
            <>
              <Pagination.Item onClick={() => setPage(1)}>{1}</Pagination.Item>
              <Pagination.Ellipsis />
            </>
          )}
          {pages.map((number) => (
            <Pagination.Item
              key={number}
              onClick={() => setPage(number)}
              active={number === pageNumber}
            >
              {number}
            </Pagination.Item>
          ))}
          {hasEllipsisAfter && (
            <>
            <Pagination.Ellipsis />
              <Pagination.Item onClick={() => setPage(numPages)}>{numPages}</Pagination.Item>            
            </>
          )}
          <Pagination.Next />
        </Pagination>
      )}
    </Container>
  );
};

export default PdfViewer;
