import React, { useContext, useEffect, useMemo, useState } from "react";
import useTranslation, { LanguageContext } from "../util/useTranslate";
import { Button, Container, Row, Image } from "react-bootstrap";
import { SocketContext } from "util/SocketProvider";
import { useParams } from "react-router-dom";
import ParallaxBackground from "./ParallaxBackground";

const Question = ({ gameSettings }) => {
  const { language } = useContext(LanguageContext);
  const translate = useTranslation();
  const { socket } = useContext(SocketContext);
  const { gameId } = useParams();
  const [timer, setTimer] = useState(null);

  const sendQuestionAnswer = (index) => {
    if (socket) {
      socket.emit("sendQuestionAnswer", {
        sessionId: gameId,
        answer: index,
      });
    }
  };

  const playerAnswering = useMemo(
    () =>
      gameSettings.players.find(
        (item) => item.id === gameSettings.question?.playerAnswering
      ),
    [gameSettings]
  );

  const playerGuessed = useMemo(
    () =>
      gameSettings.players.find(
        (item) => item.id === gameSettings.guessData?.player
      ),
    [gameSettings]
  );

  const isCorrectGuess = playerAnswering?.team === playerGuessed?.team

  const guessText = useMemo(() => {
    if(gameSettings.guessData?.doubleScore) {
      return "winCallDouble"
    }
    return gameSettings.guessData?.guess ? "winCall" : "loseCall" 
  }, [gameSettings])

  const getTeamColor = (team) => {
    return team === 1 ? "red" : "blue";
  };

  useEffect(() => {
    if (socket) {
      socket.on("countdown", (timer) => {
        setTimer(timer);
      });
    }
    return () => {
      if (socket) {
        socket.off("countdown");
      }
    };
  }, [socket]);

  return (
    <>
      <Container className="mb-3 mt-3">
        {playerGuessed && (
          <Row className="justify-content-center align-items-center pt-4">
            <h2 style={{ width: "auto" }}>
              <span style={{ color: getTeamColor(playerGuessed.team) }}>
                {playerGuessed.name}
              </span>{" "}
              {isCorrectGuess ? translate("guessed") : translate("notGuessed")}{" "}
              {`„${translate(guessText)}“`}
            </h2>
          </Row>
        )}
        {playerAnswering && (
          <Row className="justify-content-center align-items-center mt-4">
            <h2 style={{ width: "auto" }}>
              {translate("so")}
              {" "}
              <span style={{ color: getTeamColor(playerAnswering.team) }}>
                {playerAnswering.name}
              </span>{" "}
              {translate("isAnsweringQuestion")}
            </h2>
          </Row>
        )}
        <Row className="justify-content-center align-items-center mt-5">
          <div
            style={{
              backgroundImage: `url(/backgrounds/plate.png)`,
              height: "70px",
              width: "70px",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            {timer}
          </div>
        </Row>
        <Row className="justify-content-center align-items-center mt-5">
          <Image
            fluid
            style={{ objectFit: "contain", height: "30vh", minHeight: 400 }}
            src={`/questions/${language}/${
              parseInt(gameSettings.question.id) + 1
            }.jpg`}
          />
        </Row>
        {gameSettings.question.answers.map((item, index) => (
          <Row
            key={index}
            className="mt-4 justify-content-center align-items-center"
          >
            <Button
              className="w-50"
              variant="primary"
              disabled={gameSettings.question.playerAnswering !== socket.id}
              onClick={() => sendQuestionAnswer(index)}
            >
              {translate(item)}
            </Button>
          </Row>
        ))}
      </Container>
      <ParallaxBackground
        src={"/backgrounds/dvopek.png"}
        position={"bottom left"}
        size={"45vh"}
      />
      <ParallaxBackground
        src={"/backgrounds/kapulica.png"}
        position={"bottom right"}
        size={"20vh"}
      />
    </>
  );
};

export default Question;
