import PdfViewer from "Components/PdfViewer";
import croPdf from "../pdfs/cro/rules.pdf";
import enPdf from "../pdfs/en/rules.pdf";
import Navbar from "Components/Navbar";
import { LANGUAGES } from "util/useTranslate";
import ParallaxBackground from "Components/ParallaxBackground";

const Rules = () => {
  return (
    <>
      <Navbar />
      <PdfViewer
        files={{
          [LANGUAGES.CRO]: croPdf,
          [LANGUAGES.EN]: enPdf,
        }}
      />
      <ParallaxBackground
        src={"/backgrounds/banana.png"}
        position={"bottom left"}
        size={"45vh"}
      />
    </>
  );
};

export default Rules;
