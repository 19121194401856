import { createContext, useContext, useState } from "react";
import en from "../langs/en.json";
import cro from "../langs/cro.json";

export const LANGUAGES = {
  EN: "en",
  CRO: "cro",
};

const translations = {
  en,
  cro,
};

const useTranslation = () => {
  const { language } = useContext(LanguageContext);

  const translate = (key) => {
    const translation = translations[language][key];
    return translation || key;
  };

  return translate;
};

export default useTranslation;

export const LanguageContext = createContext({
  language: LANGUAGES.CRO,
  setLanguage: (newLang) => {},
});

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(LANGUAGES.CRO);

  const changeLanguage = (newLang) => {
    if (Object.values(LANGUAGES).includes(newLang)) {
      setLanguage(newLang);
    }
  };
  
  return (
    <LanguageContext.Provider
      value={{
        language,
        setLanguage: changeLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
