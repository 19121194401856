import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./Containers/Home";
import Lobby from "./Containers/Lobby";
import Game from "./Containers/Game";
import DiningEtiquette from "./Containers/DiningEtiquette";
import { LanguageProvider } from "util/useTranslate";
import SocketProvider from "util/SocketProvider";
import About from "Containers/About";
import Impressum from "Containers/Impressum";
import Rules from "Containers/Rules";

const App = () => {
  return (
    <SocketProvider>
      <LanguageProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="lobby" element={<Lobby />} />
            <Route path="impressum" element={<Impressum />} />
            <Route path="rules" element={<Rules />} />
            <Route path="diningEtiquette" element={<DiningEtiquette />} />
            <Route path="game/:gameId" element={<Game />} />
          </Routes>
        </Router>
      </LanguageProvider>
    </SocketProvider>
  );
};

export default App;
