import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import useTranslation from "../util/useTranslate";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { SocketContext } from "util/SocketProvider";
import { useParams } from "react-router-dom";
import { CheckCircleFill } from "react-bootstrap-icons";
import ParallaxBackground from "./ParallaxBackground";
import PlayerIcon from "./PlayerIcon";

const Discussion = ({ gameSettings }) => {
  const translate = useTranslation();
  const { socket } = useContext(SocketContext);
  const [chatMessages, setChatMessages] = useState([]);
  const [currentChatMessage, setCurrentChatMessage] = useState("");
  const { gameId } = useParams();

  const handlePlayerReadyToggle = (e) => {
    if (socket) {
      socket.emit("toggleReady", gameId);
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on("chatMessage", (newMessage) => {
        setChatMessages((prev) => [newMessage, ...prev]);
      });
    }
    return () => {
      if (socket) {
        socket.off("chatMessage");
      }
    };
  }, [socket]);

  useEffect(() => {
    if (socket) {
      socket.emit("joinTeamChat", gameId);
    }

    return () => {
      if (socket) {
        socket.emit("leaveTeamChat", gameId);
      }
    };
  }, [gameId, socket]);

  const sendChatMessage = (e) => {
    e.preventDefault();
    if (socket) {
      socket.emit("sendTeamChatMessage", {
        sessionId: gameId,
        message: currentChatMessage,
      });
      setCurrentChatMessage("");
    }
  };

  const currentPlayer = useMemo(
    () => gameSettings.players.find((item) => item.id === socket.id),
    [gameSettings.players, socket.id]
  );

  const getPlayerIndex = useCallback(
    (id) => gameSettings.players.findIndex((item) => item.id === id),
    [gameSettings.players]
  );

  return (
    <>
    <Container className="vh-100 mt-4">
     <Card className="d-flex gap-4 p-2 flex-row align-items-center" ><h3>{translate("playersReady")}</h3> {gameSettings.players.map((player, index) => <PlayerIcon key={player.id} playerIndex={index} active={player.ready} />)}</Card>
      <Card className="h-75 mt-4" style={{overflowY: "auto"}}>
        <Card.Body>
          {chatMessages.map(({ id, name, message }, index) => (
            <div key={index}>
              <PlayerIcon inline playerIndex={getPlayerIndex(id)} active /><b>{name}</b>: {message}
            </div>
          ))}
        </Card.Body>
      </Card>
      <form onSubmit={sendChatMessage}>
        <Row className="mt-2">
          <Col md={7} className="mb-2">
            {" "}
            <Form.Control
              type="text"
              value={currentChatMessage}
              onChange={(e) => setCurrentChatMessage(e.target.value)}
              placeholder={translate("writeMessage")}
            />
          </Col>
          <Col md={2}>
            <Button type="submit" className="w-100 mb-2" variant="primary">
              {translate("send")}
            </Button>
          </Col>
          <Col md={3}>
            <Button
              className="w-100 mb-2"
              variant={currentPlayer.ready ? "success" : "primary"}
              onClick={handlePlayerReadyToggle}
            >
              {translate("ready")} <CheckCircleFill />
            </Button>
          </Col>
        </Row>
      </form>
    </Container>
    <ParallaxBackground src={"/backgrounds/juha.png"} position={"bottom left"} size={"45vh"} />
    <ParallaxBackground src={"/backgrounds/kapula.png"} position={"bottom right"} size={"20vh"} />
    </>
  );
};

export default Discussion;
