import PdfViewer from "Components/PdfViewer";
import croPdf from "../pdfs/cro/publication.pdf";
import enPdf from "../pdfs/en/publication.pdf";
import Navbar from "Components/Navbar";
import { LANGUAGES } from "util/useTranslate";
import ParallaxBackground from "Components/ParallaxBackground";

const About = () => {
  return (
    <>
      <Navbar />
      <PdfViewer
        files={{
          [LANGUAGES.CRO]: croPdf,
          [LANGUAGES.EN]: enPdf,
        }}
      />
            <ParallaxBackground src={"/backgrounds/banana.png"} position={"bottom left"} size={"45vh"} />
    </>
  );
};

export default About;
